import * as THREE from "three";
import { useRef, useEffect } from 'react'
import { useGLTF } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { MeshBasicMaterial } from "three";


export default function RectLights(props) {

  const positions = [
    [4.92141, 2.65388, -5.39377],
    [11.5317, 2.65388, -6.54585],
    [9.25198, 2.65388, -6.64585],
    [11.5317, 2.65388, -4.14726],
    [9.25198, 2.65388, -4.14726],
    [11.5806, 2.65388, 1.53758],
    [11.7883, 2.65388, 6.65582],
    [8.18877, 2.65388, 6.65582],
    [3.72589, 2.65388, 7.48683],
    [3.72589, 2.65388, 5.7115],
    [0.326318, 2.65388, 7.48683],
    [0.326318, 2.65388, 5.7115],
    [-1.48679, 2.65388, 7.48683],
    [-1.48679, 2.65388, 5.7115],
    [-6.3595, 2.65388, 7.14687],
    [-6.3595, 2.65388, 4.97492],
    [0.326318, 2.65388, 2.41013],
    [0.326318, 2.65388, 0.634803],
    [-1.48679, 2.65388, 2.41013],
    [-1.48679, 2.65388, 0.634803],
    [-2, 2.65388, -4.91028],
    [-2, 2.65388, -6.68561],
    [-5.18496, 2.65388, -4.91028],
    [-5.18496, 2.65388, -6.68561],
    [-8.96187, 2.65388, -4.91028],
    [-8.96187, 2.65388, -6.68561],
    [-12.5201, 2.65388, -4.91028],
    [-12.5201, 2.65388, -6.68561],
  ];

    const ref = useRef()
    const temp = new THREE.Object3D()
    useEffect(() => {
      // Set positions
      for (let i = 0; i < positions.length; i++) {
        temp.position.set(positions[i][0], positions[i][1], positions[i][2])
        temp.updateMatrix()
        ref.current.setMatrixAt(i, temp.matrix)
      }
      // Update the instance
      ref.current.instanceMatrix.needsUpdate = true
    }, [positions])
    const { nodes } = useGLTF("models/RectangularLight.glb");
    const [texture] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Lights_02.001_Bake1_CyclesBake_COMBINED_ergebnis.webp",
    ]);
  
    texture.flipY = false;
    texture.colorSpace = THREE.SRGBColorSpace;
    const Material = new MeshBasicMaterial();
    Material.map = texture;
    Material.toneMapped = false;
    Material.needsUpdate = true;
    
  return(
      <instancedMesh ref={ref} args={[nodes.Lights_02001_Baked.geometry, Material, positions.length]}/>
    )
  }

  useGLTF.preload("models/RectangularLight.glb");