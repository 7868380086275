import * as THREE from "three";
import { useRef, useEffect } from 'react'
import { useGLTF } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { MeshBasicMaterial } from "three";


export default function CircleLights(props) {

  const positions = [
    [8.54683, 2.98825, -1.77938],
  [10.9773, 2.98825, -1.77938],
  [5.87332, 2.98825, -1.77938],
  [5.87332, 2.98825, 0.2],
  [5.87332, 2.98825, 2.2],
  [3.24032, 2.98825, -1.77938],
  [3.24032, 2.98825, 0.2],
  [3.24032, 2.98825, 2.2],
  [1.00065, 2.98825, -1.82938],
  [-2.1579, 2.98825, -1.25],
  [-4.86179, 2.98825, -1.25],
  [-7.35301, 2.98825, -1.25],
  [-9.96576, 2.98825, -1.25],
  [-12.4671, 2.98825, -1.25],
  ];

    const ref = useRef()
    const temp = new THREE.Object3D()
    useEffect(() => {
      // Set positions
      for (let i = 0; i < positions.length; i++) {
        temp.position.set(positions[i][0], positions[i][1], positions[i][2])
        temp.updateMatrix()
        ref.current.setMatrixAt(i, temp.matrix)
      }
      // Update the instance
      ref.current.instanceMatrix.needsUpdate = true
    }, [positions])
    const { nodes } = useGLTF("models/CircleLight.glb");
    const [texture] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "CircleLights_Bake1_CyclesBake_COMBINED_ergebnis.webp",
    ]);
  
    texture.flipY = false;
    texture.colorSpace = THREE.SRGBColorSpace;
    const Material = new MeshBasicMaterial();
    Material.map = texture;
    Material.toneMapped = false;
    Material.needsUpdate = true;
    
  return(
      <instancedMesh ref={ref} args={[nodes.CircleLights_Baked.geometry, Material, positions.length]}/>
    )
  }

  useGLTF.preload("models/CircleLight.glb");