import * as THREE from "three";
import { useRef, useEffect } from 'react'
import { useGLTF } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { MeshBasicMaterial } from "three";


export default function Plant3Plant(props) {

  const positions = [
    [2.61603, 0.919625, -6.7728, 0],
    [10.5647, 2.00255, 7.247, 0],
    [10.5647, 2.00255, 6.06, 0],
  ];

    const ref = useRef()
    const temp = new THREE.Object3D()
    useEffect(() => {
      // Set positions
      for (let i = 0; i < positions.length; i++) {
        temp.position.set(positions[i][0], positions[i][1], positions[i][2])
        temp.rotation.set(0, positions[i][3] * Math.PI, 0)
        temp.updateMatrix()
        ref.current.setMatrixAt(i, temp.matrix)
      }
      // Update the instance
      ref.current.instanceMatrix.needsUpdate = true
    }, [positions])
    const { nodes } = useGLTF("models/Plant3Plant.glb");
    const [texture] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "MONSTERA0301_ergebnis.webp",
    ]);
  
    texture.flipY = false;
    texture.colorSpace = THREE.SRGBColorSpace;
    const Material = new MeshBasicMaterial();
    Material.side = THREE.DoubleSide;
    Material.alphaTest = 1;
    Material.map = texture;
    Material.toneMapped = false;
    Material.needsUpdate = true;
    
  return(
      <instancedMesh ref={ref} args={[nodes.Plants4001.geometry, Material, positions.length]}/>
    )
  }

  useGLTF.preload("models/Plant3Plant.glb");