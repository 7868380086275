import * as THREE from "three";
import { useRef, useEffect } from 'react'
import { useGLTF } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { MeshBasicMaterial } from "three";


export default function WandbildBild(props) {

  const positions = [
    [2.2962, 1.75131, -5.78873, 0],
    [12.8498, 2.09736, 6.47685, 1],
  ];

    const ref = useRef()
    const temp = new THREE.Object3D()
    useEffect(() => {
      // Set positions
      for (let i = 0; i < positions.length; i++) {
        temp.position.set(positions[i][0], positions[i][1], positions[i][2])
        temp.rotation.set(0, positions[i][3] * Math.PI, 0)
        temp.updateMatrix()
        ref.current.setMatrixAt(i, temp.matrix)
      }
      // Update the instance
      ref.current.instanceMatrix.needsUpdate = true
    }, [positions])
    const { nodes } = useGLTF("models/Wandbild_Bild.glb");
    const [texture] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Textur_Konferenzbild_ergebnis.webp",
    ]);
  
    texture.flipY = false;
    texture.colorSpace = THREE.SRGBColorSpace;
    const Material = new MeshBasicMaterial();
    Material.side = THREE.DoubleSide;
    Material.map = texture;
    Material.toneMapped = false;
    Material.needsUpdate = true;
    
  return(
      <instancedMesh ref={ref} args={[nodes.Wandbild_1001.geometry, Material, positions.length]}/>
    )
  }

  useGLTF.preload("models/Wandbild_Bild.glb");