import * as THREE from "three";
import { useRef, useEffect } from 'react'
import { useGLTF } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { MeshBasicMaterial } from "three";


export default function TableBig2(props) {

  const tableBigPositions = [
    [3.67626, 0.576715, 6.37417],
    [-11.272, 0.576715, -5.88251],
    [-3.18273, 0.576715, -5.88251],
    [-7.27518, 0.576715, -5.88251],
    [-0.900599, 0.576715, 6.37417],
  ];

    const ref = useRef()
    const temp = new THREE.Object3D()
    useEffect(() => {
      // Set positions
      for (let i = 0; i < tableBigPositions.length; i++) {
        temp.position.set(tableBigPositions[i][0], tableBigPositions[i][1], tableBigPositions[i][2])
        temp.updateMatrix()
        ref.current.setMatrixAt(i, temp.matrix)
      }
      // Update the instance
      ref.current.instanceMatrix.needsUpdate = true
    }, [tableBigPositions])
    const { nodes } = useGLTF("models/tableBig2.glb");
    const [texture] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Desktop_Texture_ergebnis.webp",
    ]);
  
    texture.flipY = false;
    texture.colorSpace = THREE.SRGBColorSpace;
    const Material = new MeshBasicMaterial();
    Material.map = texture;
    Material.toneMapped = false;
    Material.needsUpdate = true;
    
  return(
      <instancedMesh ref={ref} args={[nodes.TableBig006.geometry, Material, tableBigPositions.length]}/>
    )
  }

  useGLTF.preload("models/tableBig2.glb");