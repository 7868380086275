import * as THREE from "three";
import { useRef, useEffect } from 'react'
import { useGLTF } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { MeshBasicMaterial } from "three";


export default function ChairLow(props) {

  const chairLowPositions = [
    [4.91083, 0.361036, -6.92938, 0],
    [4.06238, 0.361036, -6.18238, 0.5],
    [4.06238, 0.361036, -5.37514, 0.5],
    [4.06238, 0.361036, -4.56734, 0.5],
    [5.76086, 0.361036, -4.56734, -0.5],
    [5.76086, 0.361036, -6.18238, -0.5],
    [5.76086, 0.361036, -5.37514, -0.5],
    [4.91083, 0.361036, -3.84386, 1],
  ];

    const ref = useRef()
    const temp = new THREE.Object3D()
    useEffect(() => {
      // Set positions
      for (let i = 0; i < chairLowPositions.length; i++) {
        temp.position.set(chairLowPositions[i][0], chairLowPositions[i][1], chairLowPositions[i][2])
        temp.rotation.set(0, chairLowPositions[i][3] * Math.PI, 0)
        temp.updateMatrix()
        ref.current.setMatrixAt(i, temp.matrix)
      }
      // Update the instance
      ref.current.instanceMatrix.needsUpdate = true
    }, [chairLowPositions])
    const { nodes } = useGLTF("models/chairLow.glb");
    const [texture] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Chair_low_Bake1_CyclesBake_COMBINED_ergebnis.webp",
    ]);
  
    texture.flipY = false;
    texture.colorSpace = THREE.SRGBColorSpace;
    const Material = new MeshBasicMaterial();
    Material.map = texture;
    Material.toneMapped = false;
    Material.needsUpdate = true;
    
  return(
      <instancedMesh ref={ref} args={[nodes.Chair_low_Baked001.geometry, Material, chairLowPositions.length]}/>
    )
  }

  useGLTF.preload("models/chairLow.glb");