import TableBig1 from "./Models/TableBig1";
import TableBig2 from "./Models/TableBig2";
import TableBig3 from "./Models/TableBig3";
import ChairLow from "./Models/ChairLow";
import ChairHigh from "./Models/ChairHigh";
import ChairBlue from "./Models/ChairBlue";
import ChairTuerkis from "./Models/ChairTuerkis";
import ChairYellow from "./Models/ChairYellow";
import Plant2 from "./Models/Plant2";
import Wandbild from "./Models/Wandbild";
import WandbildBild from "./Models/WandbildBild";
import RectLights from "./Models/RectLights";
import CircleLights from "./Models/CircleLights";
import TableSmall from "./Models/TableSmall";
import TableSmallMonitor from "./Models/TableSmallMonitor";
import Plant1 from "./Models/Plant1";
import Plant1Plant from "./Models/Plant1Plant";
import Plant3 from "./Models/Plant3";
import Plant3Plant from "./Models/Plant3Plant";


export default function Objects(props) {
  return (
    <group>
      <TableBig1 isMobile={props.isMobile} />
      <TableBig2 isMobile={props.isMobile} />
      <TableBig3 isMobile={props.isMobile} />
      <ChairLow isMobile={props.isMobile} />
      <ChairHigh isMobile={props.isMobile} />
      <ChairBlue isMobile={props.isMobile} />
      <ChairTuerkis isMobile={props.isMobile} />
      <ChairYellow isMobile={props.isMobile} />
      <Plant2 isMobile={props.isMobile} />
      <Wandbild isMobile={props.isMobile} />
      <WandbildBild isMobile={props.isMobile} />
      <CircleLights isMobile={props.isMobile} />
      <RectLights isMobile={props.isMobile} />
      <TableSmall isMobile={props.isMobile} />
      <TableSmallMonitor isMobile={props.isMobile} />
      <Plant1 isMobile={props.isMobile} />
      <Plant1Plant isMobile={props.isMobile} />
      <Plant3 isMobile={props.isMobile} />
      <Plant3Plant isMobile={props.isMobile} />
    </group>
  );
}
