import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { MeshBasicMaterial } from "three";


export function Panoramen(props) {
  const [texPanorama1] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Panorama1.webp"]);
  texPanorama1.flipY = false;
  texPanorama1.colorSpace = THREE.SRGBColorSpace;
  const panorama1Material = new MeshBasicMaterial();
  panorama1Material.map = texPanorama1;
  panorama1Material.toneMapped = false;
  panorama1Material.needsUpdate = true;
  const [texPanorama2] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Panorama2.webp"]);
  texPanorama2.flipY = false;
  texPanorama2.colorSpace = THREE.SRGBColorSpace;
  const panorama2Material = new MeshBasicMaterial();
  panorama2Material.map = texPanorama2;
  panorama2Material.toneMapped = false;
  panorama2Material.needsUpdate = true;
  const { nodes } = useGLTF("models/Panoramen.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Panorama_1.geometry}
        material={panorama1Material}
        position={[0.071, -5.51, -25.657]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[35.451, 35.451, 17.725]}
      />
      <mesh
        geometry={nodes.Panorama_2.geometry}
        material={panorama2Material}
        position={[-8.708, -2.13, 22.379]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[35.451, 35.451, 17.725]}
      />
    </group>
  );
}

export function OuterWalls(props) {
  const [texOuterWalls] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "OuterWalls_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texOuterWalls.flipY = false;
  texOuterWalls.colorSpace = THREE.SRGBColorSpace;
  const outerWallsMaterial = new MeshBasicMaterial();
  outerWallsMaterial.map = texOuterWalls;
  outerWallsMaterial.toneMapped = false;
  outerWallsMaterial.needsUpdate = true;
  const { nodes } = useGLTF("models/outerWalls.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.OuterWalls_Baked.geometry}
        material={outerWallsMaterial}
      />
    </group>
  );
}

export function Floor(props) {
  const [texFloor] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") +  "Floor_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texFloor.flipY = false;
  texFloor.colorSpace = THREE.SRGBColorSpace;
  const floorMaterial = new MeshBasicMaterial();
  floorMaterial.map = texFloor;
  floorMaterial.toneMapped = false;
  floorMaterial.needsUpdate = true;
  const { nodes } = useGLTF("models/Floor.glb");
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Floor_Baked.geometry} material={floorMaterial} />
    </group>
  );
}

export function Ceiling(props) {
  const [texCeiling] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") +  "Ceiling_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texCeiling.flipY = false;
  texCeiling.colorSpace = THREE.SRGBColorSpace;
  const ceilingMaterial = new MeshBasicMaterial();
  ceilingMaterial.map = texCeiling;
  ceilingMaterial.toneMapped = false;
  ceilingMaterial.needsUpdate = true;
  const { nodes } = useGLTF("models/Ceiling.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Ceiling_Baked.geometry}
        material={ceilingMaterial}
      />
    </group>
  );
}

export function InnerWalls1(props) {
  const [texInnerWalls1] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") +  "innerWalls_1_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texInnerWalls1.flipY = false;
  texInnerWalls1.colorSpace = THREE.SRGBColorSpace;
  const innerWalls1Material = new MeshBasicMaterial();
  innerWalls1Material.map = texInnerWalls1;
  innerWalls1Material.toneMapped = false;
  innerWalls1Material.needsUpdate = true;
  const { nodes } = useGLTF("models/innerWalls1.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.innerWalls_1_Baked.geometry}
        material={innerWalls1Material}
      />
    </group>
  );
}

export function InnerWalls2(props) {
  const [texInnerWalls2] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") +  "innerWalls_2_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texInnerWalls2.flipY = false;
  texInnerWalls2.colorSpace = THREE.SRGBColorSpace;
  const innerWalls2Material = new MeshBasicMaterial();
  innerWalls2Material.map = texInnerWalls2;
  innerWalls2Material.toneMapped = false;
  innerWalls2Material.needsUpdate = true;
  const { nodes } = useGLTF("models/innerWalls2.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.innerWalls_2_Baked.geometry}
        material={innerWalls2Material}
      />
    </group>
  );
}

export function Eingangsbereich2(props) {
  const [texEingangsbereich2] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Eingangsbereich2_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texEingangsbereich2.flipY = false;
  texEingangsbereich2.colorSpace = THREE.SRGBColorSpace;
  const eingangsbereich2Material = new MeshBasicMaterial();
  eingangsbereich2Material.map = texEingangsbereich2;
  eingangsbereich2Material.toneMapped = false;
  eingangsbereich2Material.needsUpdate = true;
  const { nodes } = useGLTF("models/Eingangsbereich2.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Eingangsbereich2_Baked.geometry}
        material={eingangsbereich2Material}
      />
    </group>
  );
}

export function Empfang(props) {
  const [texEmpfang] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Empfang_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texEmpfang.flipY = false;
  texEmpfang.colorSpace = THREE.SRGBColorSpace;
  const empfangMaterial = new MeshBasicMaterial();
  empfangMaterial.map = texEmpfang;
  empfangMaterial.toneMapped = false;
  empfangMaterial.needsUpdate = true;
  const [texDesktop] = useLoader(TextureLoader, [
    "textures/Desktop_Texture_ergebnis.webp",
  ]);
  texDesktop.flipY = false;
  texDesktop.colorSpace = THREE.SRGBColorSpace;
  const desktop1Material = new MeshBasicMaterial();
  desktop1Material.map = texDesktop;
  desktop1Material.toneMapped = false;
  desktop1Material.needsUpdate = true;
  const { nodes } = useGLTF("models/Empfang.glb");
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Empfang002.geometry} material={desktop1Material} />
      <mesh geometry={nodes.Empfang002_1.geometry} material={empfangMaterial} />
    </group>
  );
}

export function Besprechungsraum(props) {
  const [texBesprechungsraum] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Besprechungsraum_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texBesprechungsraum.flipY = false;
  texBesprechungsraum.colorSpace = THREE.SRGBColorSpace;
  const BesprechungsraumMaterial = new MeshBasicMaterial();
  BesprechungsraumMaterial.map = texBesprechungsraum;
  BesprechungsraumMaterial.toneMapped = false;
  BesprechungsraumMaterial.needsUpdate = true;

  const [texKonferenzMonitor1] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Textur_Fernseher_Konferenz 1_ergebnis.webp",
  ]);
  texKonferenzMonitor1.flipY = false;
  texKonferenzMonitor1.colorSpace = THREE.SRGBColorSpace;
  const konferenzMonitor1Material = new MeshBasicMaterial();
  konferenzMonitor1Material.map = texKonferenzMonitor1;
  konferenzMonitor1Material.toneMapped = false;
  konferenzMonitor1Material.needsUpdate = true;
  const { nodes } = useGLTF("models/Besprechungsraum.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Besprechungsraum.geometry}
        material={BesprechungsraumMaterial}
      />
      <mesh
        geometry={nodes.Besprechungsraum_1.geometry}
        material={konferenzMonitor1Material}
      />
    </group>
  );
}

export function BesprechungsraumMitte(props) {
  const [texBesprechungsraumMitte] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Besprechungsbereich_Mitte_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texBesprechungsraumMitte.flipY = false;
  texBesprechungsraumMitte.colorSpace = THREE.SRGBColorSpace;
  const BesprechungsraumMitteMaterial = new MeshBasicMaterial();
  BesprechungsraumMitteMaterial.map = texBesprechungsraumMitte;
  BesprechungsraumMitteMaterial.toneMapped = false;
  BesprechungsraumMitteMaterial.needsUpdate = true;

  const [texKonferenzMonitor1] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Textur_Fernseher_Konferenz 1_ergebnis.webp",
  ]);
  texKonferenzMonitor1.flipY = false;
  texKonferenzMonitor1.colorSpace = THREE.SRGBColorSpace;
  const konferenzMonitor1Material = new MeshBasicMaterial();
  konferenzMonitor1Material.map = texKonferenzMonitor1;
  konferenzMonitor1Material.toneMapped = false;
  konferenzMonitor1Material.needsUpdate = true;

  const [texKonferenzMonitor2] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Textur_Fernseher_Konferenz_Buero_2_ergebnis.webp",
  ]);
  texKonferenzMonitor2.flipY = false;
  texKonferenzMonitor2.colorSpace = THREE.SRGBColorSpace;
  const konferenzMonitor2Material = new MeshBasicMaterial();
  konferenzMonitor2Material.map = texKonferenzMonitor2;
  konferenzMonitor2Material.toneMapped = false;
  konferenzMonitor2Material.needsUpdate = true;
  const { nodes } = useGLTF("models/BesprechungsraumMitte.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.BesprechungsraumMitte001.geometry}
        material={BesprechungsraumMitteMaterial}
      />
      <mesh
        geometry={nodes.BesprechungsraumMitte001_1.geometry}
        material={konferenzMonitor1Material}
      />
      <mesh
        geometry={nodes.BesprechungsraumMitte001_2.geometry}
        material={konferenzMonitor2Material}
      />
    </group>
  );
}

export function Kueche(props) {
  const [texkueche] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Kueche_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texkueche.flipY = false;
  texkueche.colorSpace = THREE.SRGBColorSpace;
  const kuecheMaterial = new MeshBasicMaterial();
  kuecheMaterial.map = texkueche;
  kuecheMaterial.toneMapped = false;
  kuecheMaterial.needsUpdate = true;
  const { nodes } = useGLTF("models/kueche.glb");
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Kueche_Baked.geometry} material={kuecheMaterial} />
    </group>
  );
}

export function SingleCubes(props) {
  const [texSingleCubes] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "SingleCubes_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texSingleCubes.flipY = false;
  texSingleCubes.colorSpace = THREE.SRGBColorSpace;
  const singleCubesMaterial = new MeshBasicMaterial();
  singleCubesMaterial.map = texSingleCubes;
  singleCubesMaterial.toneMapped = false;
  singleCubesMaterial.needsUpdate = true;
  const { nodes } = useGLTF("models/singleCubes.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.SingleCubes_Baked.geometry}
        material={singleCubesMaterial}
      />
    </group>
  );
}

export function Sitzecke1(props) {
  const [texSitzecke1] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Sitzecke_1_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texSitzecke1.flipY = false;
  texSitzecke1.colorSpace = THREE.SRGBColorSpace;
  const sitzecke1Material = new MeshBasicMaterial();
  sitzecke1Material.map = texSitzecke1;
  sitzecke1Material.toneMapped = false;
  sitzecke1Material.needsUpdate = true;
  const { nodes } = useGLTF("models/sitzecke1.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Sitzecke_1_Baked.geometry}
        material={sitzecke1Material}
      />
    </group>
  );
}

export function Sofa2(props) {
  const [texSofa2] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Sofa 02_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texSofa2.flipY = false;
  texSofa2.colorSpace = THREE.SRGBColorSpace;
  const sofa2Material = new MeshBasicMaterial();
  sofa2Material.map = texSofa2;
  sofa2Material.toneMapped = false;
  sofa2Material.needsUpdate = true;

  const [texKonferenzMonitor2] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Textur_Fernseher_Konferenz_Buero_2_ergebnis.webp",
  ]);
  texKonferenzMonitor2.flipY = false;
  texKonferenzMonitor2.colorSpace = THREE.SRGBColorSpace;
  const konferenzMonitor2Material = new MeshBasicMaterial();
  konferenzMonitor2Material.map = texKonferenzMonitor2;
  konferenzMonitor2Material.toneMapped = false;
  konferenzMonitor2Material.needsUpdate = true;

  const { nodes } = useGLTF("models/sofa2.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Sofa_02003_1.geometry}
        material={konferenzMonitor2Material}
      />
      <mesh geometry={nodes.Sofa_02003.geometry} material={sofa2Material} />
    </group>
  );
}

export function Treppe(props) {
  const [texTreppe] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Treppe_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texTreppe.flipY = false;
  texTreppe.colorSpace = THREE.SRGBColorSpace;
  const treppeMaterial = new MeshBasicMaterial();
  treppeMaterial.map = texTreppe;
  treppeMaterial.toneMapped = false;
  treppeMaterial.needsUpdate = true;
  const { nodes } = useGLTF("models/treppe.glb");
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Treppe_Baked.geometry} material={treppeMaterial} />
    </group>
  );
}

export function WindowsNorth(props) {
  const [texWindowsNorth] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Windows_north_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texWindowsNorth.flipY = false;
  texWindowsNorth.colorSpace = THREE.SRGBColorSpace;
  const windowsNorthMaterial = new MeshBasicMaterial();
  windowsNorthMaterial.map = texWindowsNorth;
  windowsNorthMaterial.toneMapped = false;
  windowsNorthMaterial.needsUpdate = true;
  const { nodes } = useGLTF("models/windowsNorth.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Windows_north_Baked.geometry}
        material={windowsNorthMaterial}
      />
    </group>
  );
}

export function WindowsSouth(props) {
  const [texWindowsSouth] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Windows_south_Bake1_CyclesBake_COMBINED_ergebnis.webp",
  ]);
  texWindowsSouth.flipY = false;
  texWindowsSouth.colorSpace = THREE.SRGBColorSpace;
  const windowsSouthMaterial = new MeshBasicMaterial();
  windowsSouthMaterial.map = texWindowsSouth;
  windowsSouthMaterial.toneMapped = false;
  windowsSouthMaterial.needsUpdate = true;
  const { nodes } = useGLTF("models/windowsSouth.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Windows_south_Baked.geometry}
        material={windowsSouthMaterial}
      />
    </group>
  );
}

export default function Scene(props) {
  return (
    <group>
        <Panoramen isMobile={props.isMobile} />
        <OuterWalls isMobile={props.isMobile} />
        <Floor isMobile={props.isMobile} />
        <Ceiling isMobile={props.isMobile} />
        <InnerWalls1 isMobile={props.isMobile} />
        <InnerWalls2 isMobile={props.isMobile} />
        <Eingangsbereich2 isMobile={props.isMobile} />
        <Empfang isMobile={props.isMobile} />
        <Besprechungsraum isMobile={props.isMobile} />
        <BesprechungsraumMitte isMobile={props.isMobile} />
        <Kueche isMobile={props.isMobile} />
        <SingleCubes isMobile={props.isMobile} />
        <Sitzecke1 isMobile={props.isMobile} />
        <Sofa2 isMobile={props.isMobile} />
        <Treppe isMobile={props.isMobile} />
        <WindowsNorth isMobile={props.isMobile} />
        <WindowsSouth isMobile={props.isMobile} />
    </group>
  );
}

useGLTF.preload("models/Panoramen.glb");
useGLTF.preload("models/outerWalls.glb");
useGLTF.preload("models/Floor.glb");
useGLTF.preload("models/Ceiling.glb");
useGLTF.preload("models/innerWalls1.glb");
useGLTF.preload("models/innerWalls2.glb");
useGLTF.preload("models/Eingangsbereich2.glb");
useGLTF.preload("models/Empfang.glb");
useGLTF.preload("models/Besprechungsraum.glb");
useGLTF.preload("models/BesprechungsraumMitte.glb");
useGLTF.preload("models/kueche.glb");
useGLTF.preload("models/singleCubes.glb");
useGLTF.preload("models/sitzecke1.glb");
useGLTF.preload("models/sofa2.glb");
useGLTF.preload("models/treppe.glb");
useGLTF.preload("models/windowsNorth.glb");
useGLTF.preload("models/windowsSouth.glb");
