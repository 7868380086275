import { Canvas } from "@react-three/fiber";
import { Suspense, useState } from "react";
import { Html, PerformanceMonitor, useProgress } from "@react-three/drei";
// import { Stats } from "@react-three/drei";
import Scene from "./Scene";
import Glass from "./Glass";
import Objects from "./Objects";
import Teleport from "./Teleport";
import { create } from "zustand";
import { Vector3 } from "three";
import { isMobile } from "react-device-detect";

export const useStore = create((set) => ({
  to: new Vector3(11, 1.6, -1),
  orbitmode: false,
  setOrbitmode: (v) => set({ orbitmode: v }),
  autoRotate: false,
  setAutoRotate: (v) => set({ autoRotate: v }),
}));

function Loader() {
  const { progress } = useProgress();
  return <Html center>{progress} % loaded</Html>;
}

export default function App() {
  const [dpr, setDpr] = useState(1);
  return (
    <>
      <Canvas dpr={dpr}>
        {/* <PerformanceMonitor
          onIncline={() => setDpr(2)}
          onDecline={() => setDpr(1)}
        > */}
          <Suspense fallback={<Loader />}>
            <Teleport />
            <Scene />
            <Glass />
            <Objects isMobile={isMobile}/>
          </Suspense>
          {/* <Stats /> */}
        {/* </PerformanceMonitor> */}
      </Canvas>
      {/* <div id="instructions">
        Click the floor slide to the circle.
        <br />
        Click a model to orbit around it.
      </div> */}
    </>
  );
}
