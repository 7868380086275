import * as THREE from "three";
import { useRef, useEffect } from 'react'
import { useGLTF } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { MeshBasicMaterial } from "three";


export default function ChairTuerkis(props) {

  const chairPositions = [
    [-1.41872, 0.433305, 0.862884, 0],
    [0.094477, 0.433305, 2.65852, 1],
    [1.0168, 0.433305, 1.75364, -0.5]
  ];

    const ref = useRef()
    const temp = new THREE.Object3D()
    useEffect(() => {
      // Set positions
      for (let i = 0; i < chairPositions.length; i++) {
        temp.position.set(chairPositions[i][0], chairPositions[i][1], chairPositions[i][2])
        temp.rotation.set(0, chairPositions[i][3] * Math.PI, 0)
        temp.updateMatrix()
        ref.current.setMatrixAt(i, temp.matrix)
      }
      // Update the instance
      ref.current.instanceMatrix.needsUpdate = true
    }, [chairPositions])
    const { nodes } = useGLTF("models/chair.glb");
    const [texture] = useLoader(TextureLoader, ["textures/" + (props.isMobile ? "Textures_Quarter/" : "") + "Chair_Tuerkis_Bake1_CyclesBake_COMBINED_ergebnis.webp",
    ]);
  
    texture.flipY = false;
    texture.colorSpace = THREE.SRGBColorSpace;
    const Material = new MeshBasicMaterial();
    Material.map = texture;
    Material.toneMapped = false;
    Material.needsUpdate = true;
    
  return(
      <instancedMesh ref={ref} args={[nodes.Chair_Tuerkis_Baked.geometry, Material, chairPositions.length]}/>
    )
  }

  useGLTF.preload("models/chair.glb");