import { useGLTF } from "@react-three/drei";
import { MeshPhysicalMaterial } from "three";

export default function Glass() {
  const { nodes } = useGLTF("/models/Glass.glb");
  const glass = new MeshPhysicalMaterial({
    metalness: 0,
    thickness: 0.01,
    roughness: 0.01,
    clearcoat: 0.03,
    clearcoatRoughness: 0,
    transmission: 0.99,
    ior: 1.3,
  });


  return (
    <group dispose={null}>
      <mesh geometry={nodes.Glass_WindowsSouth.geometry} material={glass} />
      <mesh geometry={nodes.Glass_WindowsNorth.geometry} material={glass} />
      <mesh geometry={nodes.GlassWall_1.geometry} material={glass} />
      <mesh geometry={nodes.GlassWall_2.geometry} material={glass} />
      <mesh geometry={nodes.GlassWall_3.geometry} material={glass} />
      <mesh geometry={nodes.GlassWall_4.geometry} material={glass} />
    </group>
  );
}

useGLTF.preload("/models/Glass.glb");
